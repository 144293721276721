import { createRouter, createWebHistory } from 'vue-router'
import FormVue from '../views/Registro.vue'
import QR from '../views/QR.vue'

const routes = [
  {
    path: '/:noPatrocinador(\\d+)?',
    name: 'home',
    component: () => import("../views/Index")
  },
  {
    path: '/registro/:noPatrocinador(\\d+)?',
    name: 'registro',
    component: () => import("../views/Registro")
  },
  {
    path: '/QR',
    name: 'QR',
    component: () => import("../views/QR")
  },
  {
    path: '/help/:slug',
    name: 'help',
    component: () => import("../views/Help")
  },
  /*{
    path: '/IA',
    name: 'IA',
    component: IA
  },*/
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
