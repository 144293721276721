<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 pt-10">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-20 w-auto" src="../assets/img/Virtual-Shopping-Club-logo.png" alt="Your Company" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Regístrate gratis</h2>
    </div>

    <template v-if="cargando">
      <div class="flex justify-center w-full mt-5">
        <div class="flex flex-col items-center justify-center">
          <div role="status">
            <svg aria-hidden="true" class="w-8 sm:w-20 h-8 sm:h-20 text-gray-200 animate-spin dark:text-gray-600 fill-orange-500"
                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
          </div>
          <div class="mt-5">
            <span class="text-medium">Espere, estamos validando sus datos...</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-md lg:max-w-lg">
        <form @submit.prevent="registrar()">
          <div class="mt-2">
            <label for="no-patrocinador" class="block text-sm font-medium leading-6 text-gray-900">No. Patrocinador</label>
            <div class="mt-2">
              <input v-model="noPatrocinador" id="no-patrocinador" type="text"
                     class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6
                   disabled:font-bold invalid:[&:not(:placeholder-shown):not(:focus)]:text-red-500"
                     :disabled="$route.params.noPatrocinador.length > 0">
              <span class="text-xs text-red-500" v-if="envioIncorrecto && !noPatrocinadorOk">
                Por favor, ingrese un número de patrocinador válido
              </span>
            </div>

            <template v-if="noPatrocinador.length === 0">
              <p class="text-xs">
                <strong class="text-orange-500">Nota:</strong> Si no cuenta con número patrocinador, use 1904121.
              </p>
            </template>
          </div>

          <div class="mt-2 text-sm" v-if="noPatrocinador.length >= 7">
            <template v-if="apoyador.length > 0">
              <span class="font-medium px-5">Invitado por:</span>
              <div class="text-center px-5">
                <span class="font-bold">{{apoyador}}</span>
              </div>
            </template>
            <template v-else>
              <span class="text-red-600 px-5">El número de patrocinador no existe</span>
            </template>
          </div>

          <div class="relative flex flex-row-reverse mt-2">
            <div class="flex gap-x-2">
              <div class="flex h-6 items-center">
                <input id="moral" type="checkbox"
                       class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="moral">
              </div>
              <div class="text-sm leading-6">
                <label for="moral" class="font-medium text-gray-900">Persona moral</label>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <label for="nombre" class="block text-sm font-medium leading-6 text-gray-900">{{ moral ? 'Razón social:' : 'Nombre(s):' }}</label>
            <div class="mt-2">
              <input v-model="user.nombre" id="nombre" type="text"
                     class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
              <span class="text-xs text-red-500" v-if="envioIncorrecto && user.nombre.length === 0">
                Por favor, llene este campo
              </span>
            </div>
          </div>

          <template v-if="moral">
            <div class="mt-2">
              <label for="representante-legal" class="block text-sm font-medium leading-6 text-gray-900">
                Representante legal:
              </label>
              <div class="mt-2">
                <input v-model="user.representante_legal" id="representante-legal" type="text"
                       class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                <span class="text-xs text-red-500" v-if="envioIncorrecto && user.representante_legal.length === 0">
                  Por favor, llene este campo
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex mt-2 gap-x-3">
              <div class="w-1/2">
                <label for="apellido-paterno" class="block text-sm font-medium leading-6 text-gray-900">
                  Apellido paterno:
                </label>
                <div class="mt-2">
                  <input id="apellido-paterno" v-model="user.apellido_p" type="text"
                         class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <span class="text-xs text-red-500" v-if="envioIncorrecto && user.apellido_p.length === 0">
                    Por favor, llene este campo
                  </span>
                </div>
              </div>

              <div class="w-1/2">
                <label for="apellido-materno" class="block text-sm font-medium leading-6 text-gray-900">
                  Apellido materno:
                </label>
                <div class="mt-2">
                  <input id="apellido-materno" v-model="user.apellido_m" type="text"
                         class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <span class="text-xs text-red-500" v-if="envioIncorrecto && user.apellido_m.length === 0">
                    Por favor, llene este campo
                  </span>
                </div>
              </div>
            </div>
          </template>

          <div class="mt-2">
            <label for="correo" class="block text-sm font-medium leading-6 text-gray-900">
              Correo electrónico:
            </label>
            <div class="mt-2">
              <input id="correo" @input="validar_email" v-model="user.email" type="email"
                     class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
              <span class="text-xs text-red-500" v-if="envioIncorrecto && !emailOk">
                Por favor, ingrese un correo electrónico válido
              </span>
            </div>
          </div>
          <!--        <div class="my-2 w-full pr-2" v-if="!email_disponible && email_ok">
                    <span class="text-red-500">
                      <small>El email {{ user.email }} ya está registrado</small>
                    </span>
                  </div>-->

          <div class="mt-2">
            <label for="telefono" class="block text-sm font-medium leading-6 text-gray-900">Teléfono:</label>
            <div class="mt-2">
              <input id="telefono" v-model="user.telefono" type="number"
                     class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
              <span class="text-xs text-red-500" v-if="envioIncorrecto && !telefonoOk">
                Por favor, ingrese un número telefónico válido (10 dígitos)
              </span>
            </div>
          </div>

          <div class="relative flex gap-x-3 mt-4">
            <div class="flex h-6 items-center">
              <input id="temp-password" type="checkbox"
                     class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                     v-model="passwordGenerated">
            </div>
            <div class="text-sm leading-6">
              <label for="temp-password" class="font-medium text-gray-900">Generar contraseña</label>
            </div>
          </div>
          <div class="mt-1" v-if="passwordGenerated">
            <span class="text-blue-600 text-xs">Guarde esta contraseña para iniciar sesión en la App</span>
          </div>

          <div class="flex mt-2 gap-x-3">
            <div class="w-1/2">
              <label for="password" class="block text-sm font-medium leading-6 text-gray-900">
                Contraseña:
              </label>
              <div class="mt-2">
                <input id="password" v-model="password" :type="showPassword ? 'text' : 'password'"
                       class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                   ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
              </div>
              <span class="text-xs text-red-500" v-if="password.length < 6">
              Mínimo 6 caracteres
            </span>
            </div>

            <div class="w-1/2">
              <label for="password-confirmation" class="block text-sm font-medium leading-6 text-gray-900">
                Confirmar contraseña:
              </label>
              <div class="mt-2">
                <input id="password-confirmation" v-model="passwordConfirmation"
                       :type="showPassword ? 'text' : 'password'"
                       class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                     ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
              </div>
              <span class="text-xs text-red-500"
                    v-if="passwordConfirmation.length > 0 && password !== passwordConfirmation">
              Las contraseñas no coinciden
            </span>
            </div>
          </div>

          <div class="mt-1">
            <div class="relative flex justify-end gap-x-3">
              <div class="flex h-6 items-center">
                <input id="showPassword" name="comments" type="checkbox"
                       class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="showPassword">
              </div>
              <div class="text-sm leading-6">
                <label for="showPassword" class="text-gray-500">Mostrar contraseña</label>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <p class="text-sm text-gray-500 text-justify">
              Al dar clic en "Registrarte", aceptas nuestras
              <a @click="$router.push({path: '/help/aviso-de-privacidad'})" class="text-blue-600 cursor-pointer">
                políticas de privacidad
              </a>
              , así como nuestros
              <a @click="$router.push({path: '/help/terminos-y-condiciones'})" class="text-blue-600 cursor-pointer">
                términos y condiciones.
              </a>
            </p>
          </div>

          <div class="flex my-10">
            <div class="w-1/2 h-12 pr-2">
              <button type="submit"
                      class="flex w-full justify-center rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold
                    leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2
                    focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Registrarte
              </button>
            </div>
            <div class="w-1/2 h-12 pr-2">
              <button class="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6
            text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2
            focus-visible:outline-offset-2 focus-visible:outline-blue-600" type="button" @click="$router.back()">
                Volver
              </button>
            </div>
          </div>
        </form>
      </div>
    </template>
  </div>

</template>

<script>

export default {
  data() {
    return {
      cargando: false,
      noPatrocinador: "",
      apoyador: "",
      moral: false,
      password: '',
      passwordConfirmation: '',
      passwordGenerated: false,
      showPassword: false,
      envioIncorrecto: false,

      user: {
        nombre: "",
        apellido_p: "",
        apellido_m: "",
        representante_legal: "",
        email: "",
        telefono: "",
      },
    }
  },
  mounted() {
    if(this.$route.params.noPatrocinador) {
      this.noPatrocinador = this.$route.params.noPatrocinador
      // this.getApoyador()
    }
  },
  methods: {
    getApoyador() {
      this.axios.get(
          `registro/info_patrocinador_dos/${this.noPatrocinador}`
      ).then((response) => {
        if (response.data.nombre_completo) {
          this.apoyador = response.data.nombre_completo
        }
        else{
          this.apoyador = ""
        }
      }).catch(() => {
        this.apoyador = ""
      })
    },
    async registrar() {
      if (this.datosOk && await this.validarEmail()) {
        this.cargando = true
        let data = {
          email: this.user.email,
          password: this.password,
          nombre: this.user.nombre,
          celular: this.user.telefono,
          vsc_web_registro: true
        }
        if(this.moral) {
          data.representante_legal = this.user.representante_legal
        }
        else {
          data.apellido_p = this.user.apellido_p
          data.apellido_m = this.user.apellido_m
        }

        this.axios.post(
          `api/registrar/${this.noPatrocinador}`, data
        ).then( (response) => {
          if (response.data.ok === "success") {
            this.$swal({
              title: "¡¡Registro Exitoso!!",
              icon: "success",
              confirmButtonText: "Continuar"
            }).then(() => {
              this.$router.push({path: '/QR'})
            })
          } else {
            this.$swal({
              title: "Error",
              icon: "error",
              text: "Ocurrió un error al realizar tu registro, inténtalo de nuevo más tarde"
            })
          }
          this.cargando = false
        }).catch((err) => {
          console.log(err.data)
          this.$swal({
            title: "Error",
            icon: "error",
            text: "Ocurrió un error al realizar tu registro, inténtalo de nuevo más tarde"
          })
          this.cargando = false
        })
      }
      else{
        this.envioIncorrecto = true
      }
    },
    validarEmail: async function () {
      let emailAvailable = false
      await this.axios.get("api/registrar/verificar_email", {
        params: {
          email: this.user.email
        }
      }).then((response) => {
        emailAvailable = !response.data
        if (response.data) {
          this.$swal({
            title: "Correo no disponible",
            icon: "error",
            text: `El correo ${this.user.email} ya fue registrado en Virtual Shopping Club`
          })
        }
      }).catch(err => console.log(err))
      return emailAvailable
    },
  },
  watch: {
    noPatrocinador(newValue) {
      if(newValue.length >= 7){
        this.getApoyador()
      }
      else{
        this.apoyador = ""
      }
    },
    passwordGenerated(newValue){
      if(newValue){
        let password = ""
        const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        for (let i = 0; i <= 8; i++) {
          password += chars.charAt(Math.floor(Math.random() * chars.length))
        }
        this.passwordConfirmation = password
        this.password = password
        this.showPassword = true
      }else{
        this.passwordConfirmation = ""
        this.password = ""
        this.showPassword = false
      }
    }
  },
  computed: {
    noPatrocinadorOk() {
      return /^\d*$/.test(this.noPatrocinador) && this.noPatrocinador.length >= 7 && this.apoyador.length > 0
    },
    emailOk() {
      return this.user.email.length > 0 &&
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(this.user.email)
    },
    telefonoOk() {
      return this.user.telefono.toString().length === 10 && /^\d*$/.test(this.user.telefono.toString())
    },
    datosOk() {
      return this.noPatrocinadorOk && this.emailOk && this.telefonoOk &&
          (this.moral ? (this.user.razon_social.length > 0 && this.user.representante_legal.length > 0) :
              (this.user.nombre.length > 0 && this.user.apellido_p.length > 0 && this.user.apellido_m.length > 0)) &&
          this.password.length > 6 && this.password === this.passwordConfirmation
    }
  }
}
</script>


<style scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
