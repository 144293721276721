import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

axios.defaults.baseURL = process.env.VUE_APP_URL_OFICINA
const pinia = createPinia()

createApp(App).use(pinia).use(router).use(VueAxios, axios).use(VueSweetalert2).mount('#app')
