<template>
  <!-- Info -->
  <div class="bg-white my-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h1 class="mt-10 text-4xl xl:text-5xl font-bold leading-9 tracking-tight text-orange-500 text-center leading-10">
        ¡Felicidades! Ya eres miembro de Virtual Shopping Club
      </h1>
      <p class="text-gray-900 mt-5">Pasos para disfrutar de tus nuevos beneficios:</p>
      <ul class="list-disc my-4 ml-6">
        <li>Descarga la app según tu dispositivo móvil</li>
        <li>Ingresa el correo y contraseña </li>
        <li><strong class="text-green-500">¡Disfruta! </strong></li>

      </ul>
      <div class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
        <div v-for="stat in stats" :key="stat.id"
          :class="` rounded-md p-10 mx-auto flex flex-col items-center ${stat.bg}`">
          <img :src="stat.icon" alt="" class="mb-2 max-w-64">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import galery from '../assets/QR/app galery.png';
import store from '../assets/QR/app store.png';
import play from '../assets/QR/google play.png';
export default {

  data() {
    return {
      stats: [
        { id: 1, icon: galery, bg: 'bg-blue-700', },
        { id: 2, icon: store, bg: 'bg-emerald-400', },
        { id: 3, icon: play, bg: 'bg-blue-700', },
      ],
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>


<style scoped>
/* Estilos adicionales si es necesario */
</style>
